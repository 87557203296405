import React from "react"
import { graphql } from "gatsby"
import moment from "moment"
import styled from "@emotion/styled"

import Bio from "../components/bio"
import Layout from "../components/layout"

class BlogPostTemplate extends React.Component {
  render() {
    const {
      date,
      duration,
      miles,
      publicurl,
    } = this.props.data.googleSheetRunkeeperRow

    console.log(this.props.data.googleSheetRunkeeperRow)

    const Entry = styled.p``
    return (
      <Layout location={this.props.location}>
        <h1>
          Ran for {miles} miles on {moment(date).format("dddd, MMMM Do")}
        </h1>
        <Entry>Date: {date}</Entry>
        <Entry>
          Duration: {duration.split(":")[0]} minutes, {duration.split(":")[1]}{" "}
          seconds
        </Entry>
        <Entry>
          Pace: ~{(duration.split(":")[0] / miles).toFixed(2)} minutes per mile
        </Entry>
        <Entry>
          Link:{" "}
          <a href={publicurl} target="_blank">
            See it on Runkeeper (you may need to be logged in)
          </a>
        </Entry>
        <hr />
        <Bio time={date} />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query MyQuery($id: String!) {
    googleSheetRunkeeperRow(id: { eq: $id }) {
      id
      miles
      publicurl
      date
      duration
    }
  }
`
